import {Trans, useTranslation} from "react-i18next";


export interface JoinCodeViewProps {
  codes: string[]

  team1?: string
  team2?: string
  spectator?: string
}

function JoinCodeView(props: JoinCodeViewProps) {
  const { t } = useTranslation();

  if (props.codes.length === 1) {
    const code = props.codes[0]
    return (<div className='col ko-draftinfo'>
        <Trans i18nKey="give_code_opponent"><small>Draft room code: <strong className="ko-draftname">{{code} as any}</strong></small></Trans>
      </div>
    )
  }
  const code1 = props.codes[0]
  const code2 = props.codes[1]
  const codespec = props.codes[2]
  return (<div>
    {t('multiple_codes_generated')}. {t('team_1_code')} <strong>{code1}</strong> {t('team_2_code')} <strong>{code2}</strong> {t('spectator_code')} <strong>{codespec}</strong>
  </div>)
}

export default JoinCodeView