import {DraftReactComponentProps, isOurTurn} from "./DraftReactComponentProps";
import {useTranslation} from "react-i18next";

function CoinFlipForm(props: DraftReactComponentProps) {

  const { t } = useTranslation()

  const handleCoinFlip = (e: any) => {
    e.preventDefault()
    const flip = e.target.value
    props.sendJsonMessage({
      kind: 'flip',
      payload: {
        flip: flip === 'heads' ? 0 : 1,
        password: props.password
      }
    })
  }

  if (isOurTurn(props)) {
    return (
      <div>
        <h3 className='display-5 pb-4'>Flip a Coin...</h3>
        <form>
          <div className='d-grid ko-slctbtn d-grid'>
            <button className='btn btn-primary my-2' value='heads' onClick={(e) => handleCoinFlip(e)}>{t('heads')}</button>
            <button className='btn btn-primary my-2' value='tails' onClick={(e) => handleCoinFlip(e)}>{t('tails')}</button>
          </div>
        </form>
      </div>
    )
  } else {
    return (
      <div className='d-grid'>{t('awaiting_flip')}</div>
    )
  }


}

export default CoinFlipForm