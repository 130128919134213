import {DraftActionState, DraftState, HistoryItem} from "./DraftState";
import CoinFlipForm from "./CoinFlipForm";
import useWebSocket from "react-use-websocket";
import {useEffect, useState} from "react";
import Choice from "./Choice";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {ListGroup} from "react-bootstrap";
import CompletedGame from "./CompletedGame";
import DraftStepForm, {StepItem, StepKind} from "./DraftStepForm";
import {GetWebsocketUrl} from "./DraftApi";
import {useTranslation} from "react-i18next";

export interface DraftProps {
  username: string
  password: string
}

function Draft(props: DraftProps) {
  const { sendJsonMessage, lastJsonMessage } = useWebSocket(GetWebsocketUrl, { share: true })
  const [draftState, setDraftState] = useState<DraftState | null>(null)

  const { t } = useTranslation()
  useEffect(() => {
    if (!lastJsonMessage) return
    const msg: any = lastJsonMessage

    console.log(`[DRAFT.MESSAGE] ${JSON.stringify(msg)}`)
    if (msg.kind === 'draft_update') {
      const d = msg.payload as DraftState
      setDraftState(d)
    }
  }, [lastJsonMessage])

  useEffect(() => {
    sendJsonMessage({'kind': 'query', 'payload': {}})
  }, [sendJsonMessage])

  if (!draftState) {
    return (<div>Loading...</div>)
  }

  let body: any
  if (draftState.payload.state === DraftActionState.AwaitingPlayers) {
    body = (<div>Waiting for opponent to join...</div>)
  } else if (draftState.payload.state === DraftActionState.AwaitingFlip) {
    body = (
      <CoinFlipForm username={props.username} password={props.password} draftConfig={draftState} sendJsonMessage={sendJsonMessage} />
    )
  } else if (draftState.payload.state === DraftActionState.AwaitingChoice || draftState.payload.state === DraftActionState.AwaitingSecondChoice) {
    body = (
      <Choice username={props.username} password={props.password} draftConfig={draftState} sendJsonMessage={sendJsonMessage} />
    )
  } else if (draftState.payload.state === DraftActionState.Done) {
    body = (
      <div>
        {draftState.payload.games.map((x, i) => (
          <div key={i}>
            <CompletedGame gameNum={i + 1} game={x} mapPool={draftState.payload.map_pool} ballPool={draftState.payload.ball_pool} />
          </div>
        ))}
      </div>
    )
  } else if (draftState.payload.state === DraftActionState.AwaitingMapBan || draftState.payload.state === DraftActionState.AwaitingMapPick || draftState.payload.state === DraftActionState.AwaitingBallBan || draftState.payload.state === DraftActionState.AwaitingBallPick) {
    let kind: StepKind
    let item: StepItem
    let messageKind: string
    switch (draftState.payload.state) {
      case DraftActionState.AwaitingBallBan:
        kind = StepKind.Ban
        item = StepItem.Ball
        messageKind = "ball_ban"
        break
      case DraftActionState.AwaitingBallPick:
        kind = StepKind.Pick
        item = StepItem.Ball
        messageKind = "ball_select"
        break
      case DraftActionState.AwaitingMapBan:
        kind = StepKind.Ban
        item = StepItem.Map
        messageKind = "map_ban"
        break
      case DraftActionState.AwaitingMapPick:
        kind = StepKind.Pick
        item = StepItem.Map
        messageKind = "map_select"
        break
    }
    body = (
      <DraftStepForm kind={kind} item={item} messageKind={messageKind} username={props.username} password={props.password} draftConfig={draftState} sendJsonMessage={sendJsonMessage} />
    )
  } else {
    body = (
      <Col>
        Unknown draft state: {draftState.payload.state}
      </Col>
    )
  }

  let reverseHistory: HistoryItem[] = []
  draftState.payload.history.forEach(x => reverseHistory.push(Object.assign({}, x)))
  reverseHistory.reverse()

  return (
    <div className='row DraftStart'>
          <Col className='col-md-8 col-sm-12 px-4 ko-violet'>
            <label className='py-2 mb-4'>Draft is <b>{draftState.payload.player_1_name}</b> vs <b>{draftState.payload.player_2_name}</b></label>
            {body}
          </Col>
          <Col className='col-md-4 col-sm-12 ko-draft'>
            <label className='mt-4 mx-2 py-2'>Draft History</label>
            <ListGroup>
              {reverseHistory.map((m, i) => (
                <ListGroup.Item key={i}>{t(m.key, m.params)}</ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
          <Col></Col>
    </div>
  )
}

export default Draft;
