import React, {useEffect, useState} from 'react';
import './App.css';
import './fonts/stylesheet.css'; 
import StartForm from "./StartForm";
import {DraftConfiguration, GetDraftConfiguration, GetWebsocketUrl} from "./DraftApi";
import Draft from "./Draft";
import useWebSocket from "react-use-websocket";
import './i18n';
import ErrorMessage from "./ErrorMessage";
import JoinCodeView from "./JoinCodeView";
import ConnectionStatus from "./ConnectionStatus";

function App() {
  const [draftConfig, setDraftConfig] = useState<DraftConfiguration | null>(null)
  const [drafterState, setDrafterState] = useState("START")
  const [joinCode, setJoinCode] = useState<string[]>([])
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [lastError, setLastError] = useState('')

  const { sendJsonMessage, lastJsonMessage } = useWebSocket(GetWebsocketUrl, { share: true })

  useEffect(() => {
    if (!lastJsonMessage) return
    const msg: any = lastJsonMessage

    setLastError('')
    console.log(`got parent message ${msg.kind}`)
    if (msg.kind === 'error') {
      setLastError(msg.payload.error)
    } else if (msg.kind === 'join_error') {
      setLastError(msg.payload.error)
      setDrafterState('START')
    } else if (msg.kind === 'connect_success') {
      setPassword(msg.payload.password)
    } else if (msg.kind === 'create_success') {
      console.log('room create successful')
      setDrafterState('PENDING')
      setJoinCode([msg.payload.join_code])
    } else if (msg.kind === 'trio_create_success') {
      setJoinCode([msg.payload.p1_join_code, msg.payload.p2_join_code, msg.payload.spectator_code])
      setDrafterState('PENDING')
    } else if (msg.kind === 'join_success') {
      setDrafterState('SHOW')
    }
  }, [lastJsonMessage, drafterState])

  const handleConnect = (mode: string, username: string, code: string) => {
    if (mode === "create") {
      sendJsonMessage({
        kind: 'create',
        payload: {
          username: username,
          rules: code,
          password: password
        }
      })
      setUsername(username)

    } else if (mode === 'create_trio') {
      sendJsonMessage({
        kind: 'create_trio',
        payload: {
          username: username,
          rules: code
        }
      })
      setUsername(username)
    } else if (mode === "join") {

      sendJsonMessage({
        kind: 'join',
        payload: {
          username: username,
          code: code,
          password: password
        }
      })
      setUsername(username)

    } else if (mode === 'join_spectate') {

      sendJsonMessage({
        kind: 'join_spectate',
        payload: {
          code: code
        }
      })

      setUsername('')
      setDrafterState('SHOW')
    }
  }

  useEffect(() => {
    GetDraftConfiguration().then(r => setDraftConfig(r))
    console.log('loading')
  }, [])

  let body: any
  if (drafterState === "START") {
    body = (<StartForm config={draftConfig} connect={handleConnect}/>)
  } else if (drafterState === "PENDING") {
    body = (
      <div>
        <JoinCodeView codes={joinCode} />
        <Draft username={username} password={password} />
      </div>
    )
  } else if (drafterState === "SHOW") {
    body = (<Draft username={username} password={password} />)
  }

  return (
    <div className="app">
        <ErrorMessage message={lastError} />
          {body}
        <div>
          <ConnectionStatus />
        </div>
    </div>
  );
}

export default App;
