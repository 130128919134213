import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  en: {
    translation: {
      "ball": "Ball",
      "map": "Map",
      "submit": "Submit",

      "enter_username": "Enter a username",
      "username": "Username",
      "create_new_draft": "Create a New Draft",
      "create_btn": "Create",
      "rules": "Rules",
      "join_a_draft": "Join a Draft",
      "join_code": "Join Code",
      "join_btn": "Join",
      "join_a_room": "Join a Room",
      "join_as_spectator": "Spectate a Draft",
      "spec_btn": "Spectate",
      "create_as_organizer": "Create as Organizer",

      "heads": "Heads",
      "tails": "Tails",
      "awaiting_flip": "Awaiting coin flip...",

      "pick_maps_first": "I want to pick maps first",
      "pick_balls_first": "I want to pick balls first",
      "draft_maps_first": "Maps should be drafted first",
      "draft_balls_first": "Balls should be drafted first",
      "defer_choice": "Defer choice to opponent",
      "awaiting_choice": "Awaiting opponent's choice...",

      "ban_ball_prompt_one": "Ban {{count}} ball...",
      "ban_ball_prompt_other": "Ban {{count}} balls...",
      "ban_map_prompt_one": "Ban {{count}} map...",
      "ban_map_prompt_other": "Ban {{count}} maps...",
      "pick_ball_prompt": "Pick a ball",
      "pick_map_prompt": "Pick a map",

      "give_code_opponent": "Give code <1>{{code}}</1> to your opponent",
      "multiple_codes_generated": "Join codes generated.",
      "team_1_code": "Team 1: ",
      "team_2_code": "Team 2: ",
      "spectator_code": "Spectator code: ",

      "history_draft_created": "{{who}} created the draft",
      "history_joined_player_1": "{{who}} joined the draft as player 1",
      "history_joined_player_2": "{{who}} joined the draft as player 2",
      "history_chose": "{{who}} chose {{what}}",
      "history_flip_winner": "{{who}} won the coin flip",
      "history_chosen_pick_ball": "{{who}} has chosen to pick balls first",
      "history_chosen_pick_map": "{{who}} has chosen to pick maps first",
      "history_chosen_maps_first": "{{who}} has decided maps come first",
      "history_chosen_ball_first": "{{who}} has decided balls come first",
      "history_chosen_defer": "{{who}} has chosen to defer",
      "history_map_banned": "{{who}} has banned the maps {{what}}",
      "history_map_picked": "{{who}} has picked the map {{what}} for game {{game_no}}",
      "history_ball_banned": "{{who}} has banned the balls {{what}}",
      "history_ball_picked": "{{who}} has picked the ball {{what}} for game {{game_no}}"
    }
  },
  fr: {
    translation: {
      "enter_username": "Enter a username in french"
    }
  }
}

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: 'en',
    detection: {
      order: ['navigator']
    },
    interpolation: {
      escapeValue: false
    }
})

export default i18n;
