import { useState } from "react";
import SelectableItem from "./SelectableItem";

export interface SelectableItemListProps {
  heading: string
  items: {name: string, assetUrl: string}[]
  updateList: any
  max: number
  overlay: any
}

function SelectableItemList(props: SelectableItemListProps) {
  const [checked, setChecked] = useState<string[]>([])

  const handleCheck = (name: string, selected: boolean) => {
    console.log(`handleCheck(${name}, ${selected})`)
    let updated: string[] = [...checked]
    if (selected) {
      if (checked.length < props.max) {
        updated = [...checked, name]
      }
    } else {
      updated.splice(checked.indexOf(name), 1)
    }

    setChecked(updated)
    props.updateList(updated)
  }

  return (
    <span>
      <h3 className='display-5 pb-4'>{props.heading}</h3>
      <div className='row gy-2 gx-2'>
      {props.items.map((x, i) => (
          <SelectableItem name={x.name} assetUrl={x.assetUrl} onChange={handleCheck} key={i} overlay={props.overlay} checked={checked.includes(x.name) }/>
      ))}
      </div>
    </span>
  )
}

export default SelectableItemList