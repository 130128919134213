import Alert from "react-bootstrap/Alert";

export interface ErrorMessageProps {
  message: string
}

function ErrorMessage(props: ErrorMessageProps) {
  if (!props.message) {
    return (
      <div className='col-12'></div>
    )
  }


  return (
    <div className='col-12'>
      <Alert variant='danger'>{props.message}</Alert>
    </div>
  )
}

export default ErrorMessage