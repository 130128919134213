
export enum DraftActionState {
  AwaitingPlayers,
  AwaitingFlip,
  AwaitingChoice,
  AwaitingSecondChoice,
  AwaitingMapBan,
  AwaitingMapPick,
  AwaitingBallBan,
  AwaitingBallPick,
  Done
}

export interface DraftRules {
  allow_random_ban: boolean
  allow_random_pick: boolean
  allow_refuse_ban: boolean
  name: string
  num_games: string
}

export interface AssetMap {
  [name: string]: string
}

export interface HistoryItem {
  key: string
  params: any
}

export interface DraftInfo {
  coin_flip_loser: string
  coin_flip_winner: string
  current_actor: number
  current_actor_name: string
  first_player: string
  id: string
  is_deferred: boolean
  num_games: number
  player_1_name: string
  player_2_name: string
  rules: DraftRules
  state: DraftActionState
  ball_pool: AssetMap
  map_pool: AssetMap
  history: HistoryItem[]
  first_map_player: string
  start_with: string
  eligible_balls: string[]
  eligible_maps: string[]
  num_bans: number
  games: Game[]
}

export interface DraftState {
  action: number
  draft_id: string
  payload: DraftInfo
}

export interface Game {
  banned_balls: string[]
  banned_maps: string[]
  picked_ball: string
  picked_map: string
}