import {AssetMap, Game} from "./DraftState";
import Col from "react-bootstrap/Col";

import './CompletedGame.css';

export interface CompletedGameProps {
  gameNum: number
  game: Game
  mapPool: AssetMap
  ballPool: AssetMap
}

function CompletedGame(props: CompletedGameProps) {
  return (
    <div>
        <Col className='col-12'>
          <label className="secondary py-2">Game {props.gameNum}</label>
        </Col>
        <Col className='col-12'>
          <h1>{props.game.picked_ball} @ {props.game.picked_map}</h1>
        </Col>
    </div>
  )
}

export default CompletedGame