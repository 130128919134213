import {DraftState} from "./DraftState";
import {SendJsonMessage} from "react-use-websocket/src/lib/types";

export interface DraftReactComponentProps {
  username: string
  password: string
  draftConfig: DraftState
  sendJsonMessage: SendJsonMessage
}

export function isOurTurn(draft: DraftReactComponentProps) {
  return draft.username === draft.draftConfig.payload.current_actor_name
}