const BASE_URL = process.env.NODE_ENV === "production" ? "" : "http://localhost:8080"

export interface Ball {
    name: string
}

export interface Map {
    name: string
}

export interface MapPool {
    name: string
    maps: Map[]
}

export interface BallPool {
    name: string
    balls: Ball[]
}

export interface DraftRules {
    name: string
    num_games: number
    map_pool: string
    ball_pool: string
    allow_refuse_ban: boolean
    allow_random_ban: boolean
    allow_random_pick: boolean
}

export interface DraftConfiguration {
    ball_pools: any
    map_pools: any
    draft_rules: DraftRules[]
}

export function GetDraftConfiguration(): Promise<DraftConfiguration> {
    return fetch(`${BASE_URL}/config`).then(res => res.json()).then(res => { return res as DraftConfiguration})
}

// keep the promise around so we don't actually have to call `/socketUrl` over and over again and we can just keep
// reusing the promise
let socketPromise: Promise<string>

export function GetWebsocketUrl(): Promise<string> {
    if (!socketPromise) {
        socketPromise = fetch(`${BASE_URL}/socketUrl`).then(res => res.json()).then(res => { return res.socket_url })
    }
    return socketPromise
}
