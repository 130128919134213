import {useState} from "react";
import {DraftConfiguration} from "./DraftApi";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import {useTranslation} from "react-i18next";


function StartForm(props: any) {

  const { t } = useTranslation();

  const [username, setUsername] = useState("")
  const [chosenKind, setChosenKind] = useState("")
  const [joinCode, setJoinCode] = useState("")
  const [spectatorJoinCode, setSpectatorJoinCode] = useState("")
  const [joinError, setJoinError] = useState("")

  const draftConfig = props.config as DraftConfiguration
  const drafts = draftConfig?.draft_rules.map(x => x.name)

  if (!props.config) {
    return (
      <div>Loading...</div>
    )
  }


  const handleCreate = (e: any) => {
    e.preventDefault()
    if (username.trim().length === 0) {
      setJoinError('username can not be blank')
      return
    }

    let realKind: string
    if (!chosenKind) {
      realKind = drafts[0]
    } else {
      realKind = chosenKind
    }

    props.connect('create', username, realKind)
  }

  const handleCreateTrio = (e: any) => {
    e.preventDefault()

    if (username.trim().length === 0) {
      setJoinError('username can not be blank')
      return
    }

    let realKind: string
    if (!chosenKind) {
      realKind = drafts[0]
    } else {
      realKind = chosenKind
    }

    props.connect('create_trio', username, realKind)

  }

  const handleJoin = (e: any) => {
    e.preventDefault();
    if (username.trim().length === 0) {
      setJoinError('username can not be blank')
      return
    }

    if (joinCode.trim().length === 0) {
      setJoinError('join code can not be blank')
      return
    }


    props.connect('join', username, joinCode.trim().toUpperCase())
  }

  const handleJoinAsSpectator = (e: any) => {
    e.preventDefault();

    if (spectatorJoinCode.trim().length === 0) {
      setJoinError('join code can not be blank')
      return
    }

    props.connect('join_spectate', '', spectatorJoinCode.trim().toUpperCase())
  }

  let errorDisplay = <div></div>
  if (joinError) {
    errorDisplay = <div className='col pt-3 pb-5 alert alert-primary'>
      {joinError}
    </div>
  }

  return (
    <div>
      <div className='StartForm form form-user p-4'>
        {errorDisplay}
        <div className='col pt-3 pb-5'>
          <label className='mb-3'>{t('enter_username')}</label>
          <input id='username_field' className='form-control form-control-lg' type='text' name='username' placeholder='Username' value={username} onChange={e => setUsername(e.target.value)} />
        </div>
      </div>
      <Col className='ko-dtabs mb-4'>
        <Tabs defaultActiveKey="new" id="draft-tab" className="mb-5 px-4">
          <Tab eventKey="new" title="New" className='pb-3 px-4'>
            <div className='form form-create'>
                <label className='mb-2'>{t('create_new_draft')}</label>
                <select className='form-select form-select-lg mt-2 mb-3' aria-label='.form-select-lg example' onChange={e => setChosenKind(e.target.value)}>
                  {drafts.map((o) => (
                    <option key={o} value={o}>{o}</option>
                  ))}
                </select>
                  <Col className='d-grid gap-3 d-sm-flex justify-content-sm-end pb-2'>
                    <button className='btn btn-link order-2 order-sm-1' onClick={(e) => handleCreateTrio(e)}>{t('create_as_organizer')}</button>
                    <button className='btn btn-primary order-1 order-sm-2' onClick={(e) => handleCreate(e)}>{t('create_btn')}</button>
                  </Col>
            </div>
          </Tab>
          <Tab eventKey="join" title="Join" className='pb-3 px-4'>
            <div className='form form-join'>
              <Row>
                <Col className='col-12'>
                  <label className='mb-2'>{t('join_a_draft')}</label>
                </Col>
                <Col className='col-md-8 col-sm-12'>
                  <input id='join_coide' className='form-control form-control-lg my-2' type='text' name='join_code' placeholder='Join Code' value={joinCode} onChange={(e) => setJoinCode(e.target.value)} />
                </Col>
                <Col className='col-md-4 col-sm-12'>
                  <div className='d-grid gap-2 '>
                    <button className='my-2 btn btn-primary' onClick={(e) => handleJoin(e)}>{t('join_btn')}</button>
                  </div>
                </Col>
              </Row>
            </div>
          </Tab>
          <Tab eventKey="spectate" title="Spectate" className='pb-3 px-4'>
            <div className='form form-spec'>
              <Row>
                <Col className='col-12'>
                  <label className='mb-2'>{t('join_as_spectator')}</label>
                </Col>
                <Col className='col-md-8 col-sm-12'>
                  <input id='spectator_join_code' className='form-control form-control-lg my-2' type='text' name='spectator_join_code' placeholder='Spec. Code' value={spectatorJoinCode} onChange={(e) => setSpectatorJoinCode(e.target.value)} />
                </Col>
                <Col className='col-md-4 col-sm-12'>
                  <div className='d-grid gap-2'>
                    <button className='my-2 btn btn-primary' onClick={(e) => handleJoinAsSpectator(e)}>{t('spec_btn')}</button>
                  </div>
                </Col>
              </Row>
            </div>
          </Tab>
        </Tabs>
      </Col>
    </div>
  )
}

export default StartForm;