import './SelectableItem.css'

export interface SelectableItemProps {
  name: string
  assetUrl: string
  onChange: (name: string, checked: boolean) => void
  checked: boolean
  overlay: any
}

function SelectableItem(props: SelectableItemProps) {

  const handleClick = () => {
    props.onChange(props.name, !props.checked)
  }

  let overlayStyle = "selectable-item-overlay"
  if (!props.checked) {
    overlayStyle += " selectable-item-hidden"
  }

  return (
    <span className='selectable-item' >
    <div onClick={handleClick}>
      <div>
        <div className="parent-style">
          <img src={props.overlay} className={overlayStyle} alt="" />
          <img src={props.assetUrl} alt={props.name} className="selectable-item-image" />
        </div>
        <label htmlFor={props.name} >{props.name}</label>
      </div>
    </div>
    </span>
  )
}

export default SelectableItem
