import {DraftReactComponentProps, isOurTurn} from "./DraftReactComponentProps";
import {useTranslation} from "react-i18next";

function Choice(props: DraftReactComponentProps) {
  const { t } = useTranslation()

  const handleChoice = (e: any) => {
    e.preventDefault()
    const choice = parseInt(e.target.value)
    props.sendJsonMessage({"kind":"choice", "payload": {"choice": choice, password: props.password}})
  }

  if (isOurTurn(props)) {
    return (
      <div className='ChoiceStart'>
        <form>
          {!props.draftConfig.payload.first_map_player &&
              <div className='ko-slctbtn d-grid'>
                <button className='btn btn-primary my-2' value='0' onClick={(e) => handleChoice(e)}>{t('pick_maps_first')}</button>
                <button className='btn btn-primary my-2' value='1' onClick={(e) => handleChoice(e)}>{t('pick_balls_first')}</button>
              </div>
          }
          {!props.draftConfig.payload.start_with &&
              <div className='ko-slctbtn d-grid'>
                <button className='btn btn-primary my-2' value='2' onClick={(e) => handleChoice(e)}>{t('draft_maps_first')}</button>
                <button className='btn btn-primary my-2' value='3' onClick={(e) => handleChoice(e)}>{t('draft_balls_first')}</button>
              </div>
          }
          {!props.draftConfig.payload.is_deferred && !props.draftConfig.payload.first_map_player && !props.draftConfig.payload.start_with &&
            <div className='ko-slctbtn d-grid'>
                <button className='btn btn-primary my-2' value='4' onClick={(e) => handleChoice(e)}>{t('defer_choice')}</button>
            </div>
          }
        </form>
      </div>
    )
  } else {
    return (
      <div>
        {t('awaiting_choice')}
      </div>
    )
  }
}

export default Choice