import {DraftReactComponentProps, isOurTurn} from "./DraftReactComponentProps";
import {useState} from "react";
import SelectableItemList from "./SelectableItemList";
import {useTranslation} from "react-i18next";
import check from './check.png';
import cross from './x.png';

export enum StepKind {
  Pick,
  Ban
}

export enum StepItem {
  Ball = "ball",
  Map = "map"
}

export interface DraftStepFormProps extends DraftReactComponentProps {
  kind: StepKind
  item: StepItem
  messageKind: string
}

function DraftStepForm(props: DraftStepFormProps) {
  const [selections, updateSelections] = useState([])
  const [selectionError, setSelectionError] = useState("")

  const { t } = useTranslation()

  const handleSubmit = (count: number) => {
    if (selections.length !== count) {
      console.log('incorrect count')
      setSelectionError('Invalid number of items selected')
      return
    }

    setSelectionError('')
    if (props.kind === StepKind.Ban) {
      props.sendJsonMessage({"kind": props.messageKind, "payload": {"bans": selections, password: props.password}})
    } else {
      props.sendJsonMessage({"kind": props.messageKind, "payload": {"selection": selections[0], password: props.password}})
    }

    updateSelections([])
  }

  if (isOurTurn(props)) {
    let items;
    if (props.item === StepItem.Ball) {
      items = props.draftConfig.payload.eligible_balls.map((x) => {
        return {
          name: x,
          assetUrl: props.draftConfig.payload.ball_pool[x]
        }
      })
    } else {
      items = props.draftConfig.payload.eligible_maps.map((x) => {
        return {
          name: x,
          assetUrl: props.draftConfig.payload.map_pool[x]
        }
      })
    }

    let heading: string
    let expectedItemCount = 1
    let overlay: any

    if (props.kind === StepKind.Ban) {
      expectedItemCount = props.draftConfig.payload.num_bans
      heading = t(`ban_${props.item}_prompt`, {count: expectedItemCount})
      overlay = cross
    } else {
      heading = t(`pick_${props.item}_prompt`)
      overlay = check
    }
    return (
      <div>
        {/* we use the key as a hack to force the item list to be re-rendered when the draft state changes */}
        <SelectableItemList heading={heading} items={items} updateList={updateSelections} key={props.draftConfig.payload.state} max={expectedItemCount} overlay={overlay} />
        <div className='row'>
          <div className='col-md-6 d-grid my-4'>
            <button className='btn btn-primary' onClick={(e) => {e.preventDefault(); handleSubmit(expectedItemCount)}}>{t('submit')}</button>
          </div>
          <div className='col-md-6 my-4'>
            <label className='d-block pb-2 mb-4'>Current selections:</label> {selections.join(", ")}
            <p className='selection-error p-0 m-0'>{selectionError}</p>
          </div>
        </div>
      </div>
    )

  } else {
    return (
      <div className='col'>
        <p>Awaiting {props.item} selections</p>
      </div>
    )
  }
}

export default DraftStepForm