import useWebSocket, {ReadyState} from "react-use-websocket";
import {useEffect} from "react";
import './ConnectionStatus.css'
import {GetWebsocketUrl} from "./DraftApi";

function ConnectionStatus() {
  const { readyState } = useWebSocket(GetWebsocketUrl, { share: true })

  useEffect(() => {
    console.log(readyState)
  }, [readyState])

  let className: string;

  if (readyState === ReadyState.CONNECTING) {
    className = 'status-box blue'
  } else if (readyState === ReadyState.OPEN) {
    className = 'status-box green'
  } else {
    className = 'status-box red'
  }

  return (
    <div className="status">
      Connection status
      <div className={className}></div>
    </div>

  )
}

export default ConnectionStatus